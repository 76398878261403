// import Swup from "swup";
import icons from "../icons/icons"
const $ = require("jquery")
import "select2"
import "select2/dist/css/select2.css"
import Swiper, { Navigation, Pagination, Thumbs } from "swiper"
import "swiper/swiper-bundle.css"

Swiper.use([Navigation, Pagination, Thumbs])

window.onload = function () {
  setTimeout(() => {
    document.querySelector(".preloader").classList.add("hidden")
  }, 250)
}

$(document).ready(function () {
  $(".js-example-basic-single")
    .select2({
      dropdownPosition: "below",
    })
    .on("select2:open", function () {
      $(".select2-dropdown--above").attr("id", "fix")
      $("#fix").removeClass("select2-dropdown--above")
      $("#fix").addClass("select2-dropdown--below")
    })

  function formatState(state) {
    if (!state.id) {
      return state.text
    }
    var baseUrl = "./images"
    var $state = $(
      '<span><img src="' +
        baseUrl +
        "/" +
        state.element.value.toLowerCase() +
        '.svg" class="img-flag" /> ' +
        state.text +
        "</span>"
    )
    return $state
  }
  $(".js-example-placeholder-boat").select2({
    placeholder: "Boat Type",
    templateResult: formatState,
  })
  $(".js-example-placeholder-location").select2({
    placeholder: "Location",
  })
  $(".js-example-placeholder-duration").select2({
    placeholder: "Duration",
  })
  $(".js-example-placeholder-brand").select2({
    placeholder: "Brand",
  })
  $(".js-example-placeholder-length").select2({
    placeholder: "Length",
  })
})

var app = {
  mainCardSlider() {
    const swiper = new Swiper(".js-main-card-slider", {
      slidesPerView: 4,
      spaceBetween: 40,
      navigation: {
        nextEl: ".main-slider .swiper-button-next",
        prevEl: ".main-slider .swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          centeredSlidesBounds: true,
          centeredSlides: true,
        },
        800: {
          slidesPerView: 2,
          centeredSlidesBounds: false,
          centeredSlides: false,
        },
        1000: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    })
  },
  headerMenuFn() {
    $(".header__mobile-button").on("click", () => {
      $(".header__mobile-button #hamburger").toggleClass("open")
      $(".header__menu").toggleClass("active")
    })
  },
  destinationSlider() {
    const swiper = new Swiper(".js-destination-slider", {
      slidesPerView: 2,
      spaceBetween: 40,
      navigation: {
        nextEl: ".destination-slider .swiper-button-next",
        prevEl: ".destination-slider .swiper-button-prev",
      },
      pagination: {
        el: ".destination-slider .swiper-pagination",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 2,
        },
      },
    })
  },
  boatDetailSlider() {
    var swiper = new Swiper(".js-boat-detail-thumb-slider", {
      spaceBetween: 10,
      slidesPerView: 4,
      watchSlidesProgress: true,
    })
    var swiper2 = new Swiper(".js-boat-detail-top-slider", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    })
  },

  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").each(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
      }
    })
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused")
    })
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      }
    })
  },

  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },

  load() {
    console.log("load")
  },
  detailPageFn() {
    const detailPage = document.querySelector(".detail-page")
    const header = document.querySelector(".header")
    if (detailPage) {
      header.classList.add("header--light")
    } else {
      header.classList.remove("header--light")
    }
  },

  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      let contentHeight = content.outerHeight(true)
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.find(".accordion-content").css("height", 0 + "px")
        item.removeClass("active")
      } else {
        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
          accordionGroup
            .children(".accordion-item")
            .find(".accordion-content")
            .css("height", 0 + "px")
        }
        item.addClass("active")
        item.find(".accordion-content").css("height", contentHeight)
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
    $(".js-grid").click(function () {
      $(".js-list").removeClass("active")
      $(this).addClass("active")
      $(".list-content__grid ").removeClass("list-content__grid--list")
    })
    $(".js-list").click(function () {
      $(".js-grid").removeClass("active")
      $(this).addClass("active")
      $(".list-content__grid ").addClass("list-content__grid--list")
    })
    $(".js-open-filter").click(function () {
      $(".list-content__left, .list-content__left__overlay").addClass("active")
    })

    $(".list-content__left__overlay").click(function () {
      $(".list-content__left, .list-content__left__overlay").removeClass(
        "active"
      )
    })
  },
  resized() {
    console.log("resized")
  },

  // swiperTest() {
  //   const swiper = new Swiper(".swiper-container", {
  //     loop: true,
  //   });
  // },

  init: function () {
    app.iconSpriteFn()
    app.load()
    app.mainCardSlider()
    app.destinationSlider()
    app.detailPageFn()
    app.boatDetailSlider()
    app.accordionFn()
    app.inputFocusandTexted()
    app.headerMenuFn()
    // app.swiperTest();
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  // const swup = new Swup({
  //   cache: true,
  // });
  // swup.on("contentReplaced", function () {
  //   swup.options.containers.forEach(() => {
  //     app.iconSpriteFn();
  //     app.swiperTest();
  //   });
  // });
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
