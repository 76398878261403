const icons = [
  {
    iconId: "iconFacebook",
    iconSvg: `<svg viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.41503 20L4.38636 10.9091H0.75V7.27273H4.38636V5C4.38636 1.626 6.47574 0 9.48558 0C10.9273 0 12.1664 0.107336 12.5275 0.155318V3.68135L10.4401 3.68229C8.80314 3.68229 8.48619 4.46013 8.48619 5.60155V7.27273H13.25L11.4318 10.9091H8.48619V20H4.41503Z"/>
		</svg>
		`,
  },
  {
    iconId: "iconTwitter",
    iconSvg: `<svg  viewBox="0 0 20 17"  xmlns="http://www.w3.org/2000/svg">
		<path d="M20 2.41667C19.25 2.75 18.5 3 17.6667 3.08333C18.5 2.58333 19.1667 1.75 19.5 0.833333C18.6667 1.33333 17.8333 1.66667 16.9167 1.83333C16.1667 1 15.0833 0.5 13.9167 0.5C11.6667 0.5 9.83333 2.33333 9.83333 4.58333C9.83333 4.91667 9.83333 5.25 9.91667 5.5C6.41667 5.33333 3.41667 3.66667 1.41667 1.16667C1 1.83333 0.833333 2.5 0.833333 3.25C0.833333 4.66667 1.58333 5.91667 2.66667 6.66667C2 6.66667 1.33333 6.5 0.833333 6.16667C0.833333 6.16667 0.833333 6.16667 0.833333 6.25C0.833333 8.25 2.25 9.91667 4.08333 10.25C3.75 10.3333 3.41667 10.4167 3 10.4167C2.75 10.4167 2.5 10.4167 2.25 10.3333C2.75 12 4.25 13.1667 6.08333 13.1667C4.66667 14.25 2.91667 14.9167 1 14.9167C0.666667 14.9167 0.333333 14.9167 0 14.8333C1.83333 16 4 16.6667 6.25 16.6667C13.8333 16.6667 17.9167 10.4167 17.9167 5C17.9167 4.83333 17.9167 4.66667 17.9167 4.5C18.75 3.91667 19.4167 3.16667 20 2.41667Z" />
		</svg>
		`,
  },
  {
    iconId: "iconInstagram",
    iconSvg: `<svg viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5.87667 0.06C6.94333 0.0116667 7.28417 0 10 0C12.7158 0 13.0567 0.0116667 14.1233 0.06C15.7483 0.134167 17.1758 0.5325 18.3217 1.67833C19.4675 2.82417 19.8658 4.25167 19.94 5.87667C19.9883 6.94333 20 7.28417 20 10C20 12.7158 19.9883 13.0567 19.94 14.1233C19.8658 15.7483 19.4675 17.1758 18.3217 18.3217C17.1758 19.4675 15.7483 19.8658 14.1233 19.94C13.0567 19.9883 12.7158 20 10 20C7.28417 20 6.94333 19.9883 5.87667 19.94C4.25167 19.8658 2.82417 19.4675 1.67833 18.3217C0.5325 17.1758 0.134167 15.7483 0.06 14.1233C0.0116667 13.0567 0 12.7158 0 10C0 7.28417 0.0116667 6.94333 0.06 5.87667C0.134167 4.25167 0.5325 2.82417 1.67833 1.67833C2.82417 0.5325 4.25167 0.134167 5.87667 0.06ZM14.0408 1.86C12.9867 1.81167 12.67 1.80167 10 1.80167C7.33 1.80167 7.01333 1.81167 5.95917 1.86C4.82083 1.91167 3.765 2.14 2.9525 2.9525C2.14 3.765 1.91167 4.82083 1.86 5.95917C1.81167 7.01333 1.80167 7.33 1.80167 10C1.80167 12.67 1.81167 12.9867 1.86 14.0408C1.91167 15.1792 2.14 16.235 2.9525 17.0475C3.765 17.86 4.82083 18.0883 5.95917 18.14C7.01333 18.1883 7.33 18.1983 10 18.1983C12.67 18.1983 12.9867 18.1883 14.0408 18.14C15.1792 18.0883 16.235 17.86 17.0475 17.0475C17.86 16.235 18.0883 15.1792 18.14 14.0408C18.1883 12.9867 18.1983 12.67 18.1983 10C18.1983 7.33 18.1883 7.01333 18.14 5.95917C18.0883 4.82083 17.86 3.765 17.0475 2.9525C16.235 2.14 15.1792 1.91167 14.0408 1.86Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M4.86499 10.0002C4.86499 7.1644 7.16416 4.86523 9.99999 4.86523C12.8358 4.86523 15.135 7.1644 15.135 10.0002C15.135 12.8361 12.8358 15.1352 9.99999 15.1352C7.16416 15.1352 4.86499 12.8361 4.86499 10.0002ZM6.66666 10.0002C6.66666 11.8411 8.15916 13.3336 9.99999 13.3336C11.8408 13.3336 13.3333 11.8411 13.3333 10.0002C13.3333 8.1594 11.8408 6.6669 9.99999 6.6669C8.15916 6.6669 6.66666 8.1594 6.66666 10.0002Z" />
		<circle cx="15.3383" cy="4.66143" r="1.2" />
		</svg>
		`,
  },
  {
    iconId: "iconLinkedin",
    iconSvg: `<svg  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.833333 0H19.1667C19.6667 0 20 0.333333 20 0.833333V19.1667C20 19.6667 19.6667 20 19.1667 20H0.833333C0.333333 20 0 19.6667 0 19.1667V0.833333C0 0.333333 0.333333 0 0.833333 0ZM3 17.0833H5.91667H6V7.5H3V17.0833ZM4.41667 6.16667C3.5 6.16667 2.66667 5.41667 2.66667 4.41667C2.66667 3.5 3.41667 2.66667 4.41667 2.66667C5.33333 2.66667 6.16667 3.41667 6.16667 4.41667C6.16667 5.41667 5.41667 6.16667 4.41667 6.16667ZM14.0833 17.0833H17.0833V11.9167C17.0833 9.33333 16.5 7.33333 13.5 7.33333C12 7.33333 11.0833 8.08333 10.6667 8.83333V7.5H7.83333V17.0833H10.8333V12.3333C10.8333 11.0833 11 9.91667 12.5833 9.91667C14.0833 9.91667 14.0833 11.3333 14.0833 12.4167V17.0833Z" />
</svg>
`,
  },
  {
    iconId: "iconWhatsapp",
    iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
		<path d="M414.7,97.1A222.2,222.2,0,0,0,256.9,32c-122.9,0-223,99.6-223,222A219.7,219.7,0,0,0,63.7,365L32,480l118.2-30.9a223.4,223.4,0,0,0,106.7,27h0c123,0,223-99.6,223.1-222A220.1,220.1,0,0,0,414.7,97.1ZM256.9,438.7h0a185.5,185.5,0,0,1-94.4-25.8l-6.8-4L85.6,427.3l18.7-68.1-4.4-7A183.7,183.7,0,0,1,71.5,254C71.5,152.3,154.7,69.5,257,69.5S442.1,152,442.3,254.1,359.1,438.7,256.9,438.7ZM358.6,300.5c-5.5-2.8-33-16.2-38.1-18.1s-8.8-2.8-12.5,2.8-14.4,18-17.6,21.8-6.5,4.1-12.1,1.3-23.6-8.6-44.8-27.5c-16.6-14.7-27.8-32.9-31-38.4s-.4-8.6,2.4-11.3,5.6-6.5,8.4-9.8,3.7-5.5,5.5-9.2,1-7-.4-9.7-12.6-30.1-17.2-41.2-9.1-9.4-12.6-9.5-7-.2-10.6-.2a20.4,20.4,0,0,0-14.9,6.9c-5.1,5.6-19.5,19-19.5,46.3s20,53.7,22.7,57.4,39.3,59.7,95.3,83.7a293.3,293.3,0,0,0,31.7,11.7c13.4,4.2,25.5,3.6,35.1,2.2s33-13.4,37.7-26.4,4.6-24,3.2-26.3S364.2,303.2,358.6,300.5Z" transform="translate(-32 -32)"></path>
		</svg>
		`,
  },
  {
    iconId: "iconCabin",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20">
			<path class="st0" d="M11.91,20c-0.06,0-0.11-0.01-0.17-0.03l-11.41-4C0.13,15.9,0,15.71,0,15.5V0.51c0-0.02,0-0.05,0-0.08
				c0.01-0.06,0.02-0.11,0.05-0.16c0.03-0.07,0.08-0.12,0.14-0.17c0.06-0.04,0.13-0.08,0.2-0.09C0.43,0,0.47,0,0.51,0H14.5
				C14.78,0,15,0.22,15,0.5v15c0,0.28-0.22,0.5-0.5,0.5h-2.09v3.5c0,0.16-0.08,0.31-0.21,0.41C12.11,19.97,12.01,20,11.91,20z
				 M1,15.15l10.41,3.65V3.89L1,1.15V15.15z M12.41,15H14V1H4.37l7.67,2.02c0.22,0.06,0.37,0.26,0.37,0.48V15z"/>
			<path class="st0" d="M9,12c-0.28,0-0.5-0.22-0.5-0.5v-2C8.5,9.22,8.72,9,9,9s0.5,0.22,0.5,0.5v2C9.5,11.78,9.28,12,9,12z"/>
		</svg>
		`,
  },
  {
    iconId: "iconEngine",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18.99" >
			<path class="st0" d="M0.93,17c-0.05,0-0.11-0.01-0.16-0.03c-0.13-0.04-0.24-0.14-0.29-0.26c-1.2-2.59-0.09-5.58,2.59-6.96
				c2.61-1.4,5.97-0.79,7.81,1.42c0.1,0.12,0.14,0.27,0.11,0.42s-0.12,0.28-0.26,0.34l-9.57,5.02C1.09,16.98,1.01,17,0.93,17z M6.1,10
				c-0.88,0-1.76,0.21-2.56,0.63c-2.03,1.04-2.97,3.19-2.34,5.16l8.49-4.45C8.72,10.47,7.42,10,6.1,10z"/>
			<path class="st0" d="M15.07,18.99c-1.03,0-2.07-0.24-3.01-0.73c-2.65-1.39-3.76-4.38-2.59-6.97c0.06-0.13,0.16-0.22,0.29-0.27
				s0.27-0.03,0.4,0.03l9.58,5.03c0.13,0.07,0.23,0.2,0.26,0.35c0.03,0.15-0.01,0.3-0.11,0.42C18.7,18.24,16.9,18.99,15.07,18.99z
				 M10.19,12.21c-0.61,1.97,0.34,4.12,2.34,5.17c2.01,1.05,4.54,0.73,6.15-0.72L10.19,12.21z"/>
			<path class="st0" d="M10.5,12c-0.12,0-0.24-0.04-0.33-0.13C10.06,11.78,10,11.64,10,11.5v-11c0-0.14,0.06-0.28,0.17-0.37
				c0.11-0.1,0.25-0.14,0.39-0.12c3.13,0.38,5.46,2.96,5.44,6c0.02,3.03-2.31,5.61-5.44,5.99C10.54,12,10.52,12,10.5,12z M11,1.09
				v9.81c2.33-0.54,4.02-2.55,4-4.9C15.02,3.65,13.34,1.63,11,1.09z"/>
		</svg>
		`,
  },
  {
    iconId: "iconAngle",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 181.8" xml:space="preserve">
		<g>
			<path d="M9.1,181.8c-2.3,0-4.7-0.9-6.4-2.7c-3.6-3.6-3.6-9.3,0-12.9l75.4-75.4L2.7,15.5c-3.6-3.6-3.6-9.3,0-12.9s9.3-3.6,12.9,0
				l81.8,81.8c3.6,3.6,3.6,9.3,0,12.9l-81.8,81.8C13.7,180.9,11.4,181.8,9.1,181.8z"/>
		</g>
		</svg>
		
		`,
  },
  {
    iconId: "iconSearch",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.93 16.93">
	<path class="st0" d="M7.4,14.8C3.32,14.8,0,11.48,0,7.4C0,3.32,3.32,0,7.4,0c4.08,0,7.4,3.32,7.4,7.4
		C14.8,11.48,11.48,14.8,7.4,14.8z M7.4,2C4.42,2,2,4.42,2,7.4c0,2.98,2.42,5.4,5.4,5.4s5.4-2.42,5.4-5.4C12.8,4.42,10.38,2,7.4,2z"
		/>
	<path class="st0" d="M15.93,16.93c-0.26,0-0.51-0.1-0.71-0.29l-4.01-4.01c-0.39-0.39-0.39-1.02,0-1.41s1.02-0.39,1.41,0l4.01,4.01
		c0.39,0.39,0.39,1.02,0,1.41C16.44,16.84,16.19,16.93,15.93,16.93z"/>
</svg>
`,
  },
  {
    iconId: "iconArrow",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 30">
	<path class="st0" d="M1,30c-0.2,0-0.4-0.06-0.58-0.19c-0.45-0.32-0.55-0.95-0.23-1.4L9.77,15L0.19,1.58
		c-0.32-0.45-0.22-1.07,0.23-1.4c0.45-0.32,1.07-0.22,1.4,0.23l10,14c0.25,0.35,0.25,0.81,0,1.16l-10,14C1.62,29.85,1.31,30,1,30z"
		/>
</svg>
`,
  },
  {
    iconId: "iconGrid",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="overflow:visible;enable-background:new 0 0 100 100;" xml:space="preserve">
		<rect class="st0" width="40" height="40"/>
		<rect x="60" class="st0" width="40" height="40"/>
		<rect y="60" class="st0" width="40" height="40"/>
		<rect x="60" y="60" class="st0" width="40" height="40"/>
		</svg>
		
		`,
  },
  {
    iconId: "iconShare",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			  viewBox="0 0 100 106.7" xml:space="preserve">
		<g>
			<path d="M70,26.7c-0.9,0-1.7-0.3-2.4-1L50,8L32.4,25.7c-1.3,1.3-3.4,1.3-4.7,0c-1.3-1.3-1.3-3.4,0-4.7l20-20c1.3-1.3,3.4-1.3,4.7,0
				l20,20c1.3,1.3,1.3,3.4,0,4.7C71.7,26.3,70.9,26.7,70,26.7z"/>
		</g>
		<g>
			<path d="M50,73.3c-1.8,0-3.3-1.5-3.3-3.3V3.3C46.7,1.5,48.2,0,50,0s3.3,1.5,3.3,3.3V70C53.3,71.8,51.8,73.3,50,73.3z"/>
		</g>
		<g>
			<path d="M96.7,106.7H3.3c-1.8,0-3.3-1.5-3.3-3.3v-60C0,41.5,1.5,40,3.3,40H30c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3H6.7V100
				h86.7V46.7H70c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3h26.7c1.8,0,3.3,1.5,3.3,3.3v60C100,105.2,98.5,106.7,96.7,106.7z"/>
		</g>
		</svg>
		`,
  },
  {
    iconId: "iconWatchVideo",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 100 81.2" xml:space="preserve">

		<g>
			<path d="M40.6,62.5c-0.5,0-1.1-0.1-1.5-0.4c-1-0.6-1.6-1.6-1.6-2.7V21.9c0-1.1,0.6-2.2,1.6-2.7c1-0.5,2.2-0.5,3.1,0l31.2,18.8
				c0.9,0.6,1.5,1.6,1.5,2.7s-0.6,2.1-1.5,2.7L42.2,62.1C41.7,62.4,41.2,62.5,40.6,62.5z M43.8,27.4v26.5l22.1-13.2L43.8,27.4z"/>
		</g>
		<g>
			<path d="M96.9,81.2H3.1c-1.7,0-3.1-1.4-3.1-3.1v-75C0,1.4,1.4,0,3.1,0h93.8c1.7,0,3.1,1.4,3.1,3.1v75C100,79.9,98.6,81.2,96.9,81.2
				z M6.2,75h87.5V6.2H6.2V75z"/>
		</g>
		</svg>
		`,
  },
  {
    iconId: "iconBrochure",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			 viewBox="0 0 100 114.3"  xml:space="preserve">
		<g>
			<path d="M75,114.3H3.6c-2,0-3.6-1.6-3.6-3.6V25c0-2,1.6-3.6,3.6-3.6H75c2,0,3.6,1.6,3.6,3.6v85.7C78.6,112.7,77,114.3,75,114.3z
				 M7.1,107.1h64.3V28.6H7.1V107.1z"/>
		</g>
		<g>
			<path d="M96.4,100c-2,0-3.6-1.6-3.6-3.6V7.1h-75c-2,0-3.6-1.6-3.6-3.6S15.9,0,17.9,0h78.6c2,0,3.6,1.6,3.6,3.6v92.9
				C100,98.4,98.4,100,96.4,100z"/>
		</g>
		<g>
			<path d="M53.6,50H25c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6h28.6c2,0,3.6,1.6,3.6,3.6C57.1,48.4,55.5,50,53.6,50z"/>
		</g>
		<g>
			<path d="M53.6,71.4H25c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6h28.6c2,0,3.6,1.6,3.6,3.6C57.1,69.8,55.5,71.4,53.6,71.4z"/>
		</g>
		<g>
			<path d="M53.6,92.9H25c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6h28.6c2,0,3.6,1.6,3.6,3.6S55.5,92.9,53.6,92.9z"/>
		</g>
		</svg>
		`,
  },
  {
    iconId: "iconList",
    iconSvg: `<!-- Generator: Adobe Illustrator 25.2.1, SVG Export Plug-In  -->
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 85"  xml:space="preserve">
		<g>
			<path d="M97.5,5h-95C1.1,5,0,3.9,0,2.5S1.1,0,2.5,0h95c1.4,0,2.5,1.1,2.5,2.5S98.9,5,97.5,5z"/>
		</g>
		<g>
			<path d="M97.5,45h-95C1.1,45,0,43.9,0,42.5S1.1,40,2.5,40h95c1.4,0,2.5,1.1,2.5,2.5S98.9,45,97.5,45z"/>
		</g>
		<g>
			<path d="M97.5,85h-95C1.1,85,0,83.9,0,82.5S1.1,80,2.5,80h95c1.4,0,2.5,1.1,2.5,2.5S98.9,85,97.5,85z"/>
		</g>
		</svg>
		`,
  },
  {
    iconId: "iconArrowR",
    iconSvg: `
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 9" >
			<path class="st0" d="M11.5,5h-11C0.22,5,0,4.78,0,4.5S0.22,4,0.5,4h11C11.78,4,12,4.22,12,4.5S11.78,5,11.5,5z"/>
			<path class="st0" d="M7.5,9C7.37,9,7.24,8.95,7.15,8.85c-0.2-0.2-0.2-0.51,0-0.71l3.65-3.65L7.15,0.85c-0.2-0.2-0.2-0.51,0-0.71
				s0.51-0.2,0.71,0l4,4c0.2,0.2,0.2,0.51,0,0.71l-4,4C7.76,8.95,7.63,9,7.5,9z"/>
		</svg>
		`,
  },
  {
    iconId: "iconVideoPlay",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 34 40">
		<path class="st0" d="M9.67,0.58l23.12,17.01c1.61,1.18,1.61,3.62,0,4.81L9.67,39.42C7.82,40.79,5.22,39.58,5,37.33
			c0.32-0.14,0.63-0.33,0.92-0.53l17.3-12.73l0.07-0.05l0.31-0.22c1.21-0.89,1.9-2.28,1.9-3.79c0-1.52-0.7-2.9-1.9-3.79l-6.83-5.04
			L5.92,3.2C5.63,2.99,5.32,2.81,5,2.67C5.22,0.42,7.8-0.79,9.67,0.58z M15.91,12.45l7,4.97c1.46,1.03,1.46,3.14,0.02,4.17l-0.4,0.29
			L4.8,34.43c-2,1.42-4.8,0.03-4.8-2.37V6.94c0-2.4,2.8-3.78,4.8-2.37L15.91,12.45z"/>
		</svg>
		`,
  },
  {
    iconId: "iconPhone",
    iconSvg: `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
	<path class="st0" d="M12.5,15C5.61,15,0,9.39,0,2.5c0-0.13,0.05-0.26,0.15-0.35l2-2c0.2-0.2,0.51-0.2,0.71,0l3,3
		c0.2,0.2,0.2,0.51,0,0.71L4.21,5.5l5.29,5.29l1.65-1.65c0.2-0.2,0.51-0.2,0.71,0l3,3c0.2,0.2,0.2,0.51,0,0.71l-2,2
		C12.76,14.95,12.63,15,12.5,15z M1,2.71C1.11,8.88,6.12,13.89,12.29,14l1.5-1.5l-2.29-2.29l-1.65,1.65c-0.2,0.2-0.51,0.2-0.71,0
		l-6-6c-0.2-0.2-0.2-0.51,0-0.71L4.79,3.5L2.5,1.21L1,2.71z"/>
	<path class="st0" d="M11.5,8C11.22,8,11,7.78,11,7.5C11,5.57,9.43,4,7.5,4C7.22,4,7,3.78,7,3.5S7.22,3,7.5,3C9.98,3,12,5.02,12,7.5
		C12,7.78,11.78,8,11.5,8z"/>
	<path class="st0" d="M14.5,8C14.22,8,14,7.78,14,7.5C14,3.92,11.08,1,7.5,1C7.22,1,7,0.78,7,0.5S7.22,0,7.5,0
		C11.64,0,15,3.36,15,7.5C15,7.78,14.78,8,14.5,8z"/>
</svg>
`,
  },
  {
    iconId: "iconUsers",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" > 
	<path class="st0" d="M9,10H0V8.84c0-0.62,0.27-1.18,0.74-1.55C1.47,6.7,2.73,6,4.5,6c1.77,0,3.02,0.7,3.76,1.28
		C8.73,7.65,9,8.22,9,8.84V10z M1,9h7V8.84c0-0.31-0.13-0.59-0.36-0.77l0,0C7.03,7.58,5.99,7,4.5,7C3.01,7,1.97,7.58,1.36,8.07
		C1.13,8.25,1,8.53,1,8.84V9z"/>
	<path class="st0" d="M4.5,5C3.12,5,2,3.88,2,2.5S3.12,0,4.5,0S7,1.12,7,2.5S5.88,5,4.5,5z M4.5,1C3.67,1,3,1.67,3,2.5S3.67,4,4.5,4
		S6,3.33,6,2.5S5.33,1,4.5,1z"/>
	<path class="st0" d="M11.5,5C10.12,5,9,3.88,9,2.5S10.12,0,11.5,0S14,1.12,14,2.5S12.88,5,11.5,5z M11.5,1C10.67,1,10,1.67,10,2.5
		S10.67,4,11.5,4S13,3.33,13,2.5S12.33,1,11.5,1z"/>
	<path class="st0" d="M16,10h-5V8.84c0-0.72-0.2-1.42-0.57-2.04l-0.4-0.67l0.77-0.09C11.02,6.01,11.26,6,11.5,6
		c1.77,0,3.03,0.7,3.76,1.28C15.73,7.65,16,8.22,16,8.84V10z M12,9h3V8.84c0-0.31-0.13-0.59-0.36-0.77h0
		C14.05,7.6,13.06,7.04,11.65,7C11.88,7.58,12,8.21,12,8.84V9z"/>
</svg>

`,
  },
  {
    iconId: "iconWidth",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 7">
	<path class="st0" d="M15.5,4h-6C9.22,4,9,3.78,9,3.5S9.22,3,9.5,3h6C15.78,3,16,3.22,16,3.5S15.78,4,15.5,4z"/>
	<path class="st0" d="M12.5,7c-0.13,0-0.26-0.05-0.35-0.15c-0.2-0.2-0.2-0.51,0-0.71l2.65-2.65l-2.65-2.65c-0.2-0.2-0.2-0.51,0-0.71
		s0.51-0.2,0.71,0l3,3c0.2,0.2,0.2,0.51,0,0.71l-3,3C12.76,6.95,12.63,7,12.5,7z"/>
	<path class="st0" d="M6.5,4h-6C0.22,4,0,3.78,0,3.5S0.22,3,0.5,3h6C6.78,3,7,3.22,7,3.5S6.78,4,6.5,4z"/>
	<path class="st0" d="M3.5,7C3.37,7,3.24,6.95,3.15,6.85l-3-3c-0.2-0.2-0.2-0.51,0-0.71l3-3c0.2-0.2,0.51-0.2,0.71,0
		s0.2,0.51,0,0.71L1.21,3.5l2.65,2.65c0.2,0.2,0.2,0.51,0,0.71C3.76,6.95,3.63,7,3.5,7z"/>
</svg>
`,
  },
  {
    iconId: "iconCalendar",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" " viewBox="0 0 16 16" >
	<path class="st0" d="M15.5,6h-15C0.22,6,0,5.78,0,5.5S0.22,5,0.5,5h15C15.78,5,16,5.22,16,5.5S15.78,6,15.5,6z"/>
	<path class="st0" d="M14.5,16h-13C0.67,16,0,15.33,0,14.5v-11C0,2.67,0.67,2,1.5,2h13C15.33,2,16,2.67,16,3.5v11
		C16,15.33,15.33,16,14.5,16z M1.5,3C1.22,3,1,3.22,1,3.5v11C1,14.78,1.22,15,1.5,15h13c0.28,0,0.5-0.22,0.5-0.5v-11
		C15,3.22,14.77,3,14.5,3H1.5z"/>
	<path class="st0" d="M4.5,3C4.22,3,4,2.78,4,2.5v-2C4,0.22,4.22,0,4.5,0S5,0.22,5,0.5v2C5,2.78,4.78,3,4.5,3z"/>
	<path class="st0" d="M11.5,3C11.22,3,11,2.78,11,2.5v-2C11,0.22,11.22,0,11.5,0S12,0.22,12,0.5v2C12,2.78,11.78,3,11.5,3z"/>
</svg>
`,
  },
  {
    iconId: "iconWorkedhour",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
	<path class="st0" d="M8,11C8,11,8,11,8,11c-0.8,0-1.56-0.31-2.12-0.88c-1-1-4.44-7.64-4.82-8.39C0.96,1.54,0.99,1.3,1.15,1.15
		C1.3,0.99,1.54,0.96,1.73,1.06c0.75,0.39,7.39,3.82,8.39,4.82c0,0,0,0,0,0c1.17,1.17,1.17,3.07,0,4.24C9.56,10.69,8.8,11,8,11z
		 M2.68,2.68c1.47,2.78,3.33,6.16,3.91,6.74C6.96,9.79,7.47,10,8,10l0,0c0.53,0,1.04-0.21,1.41-0.59c0.78-0.78,0.78-2.05,0-2.83
		C8.84,6.01,5.46,4.14,2.68,2.68z"/>
	<path class="st0" d="M8,16c-4.41,0-8-3.59-8-8c0-0.73,0.1-1.44,0.29-2.13C0.36,5.6,0.64,5.45,0.9,5.52
		c0.27,0.07,0.42,0.35,0.35,0.62C1.08,6.74,1,7.37,1,8c0,3.86,3.14,7,7,7s7-3.14,7-7c0-3.86-3.14-7-7-7C7.37,1,6.74,1.08,6.13,1.25
		C5.87,1.32,5.59,1.17,5.52,0.9C5.44,0.64,5.6,0.36,5.87,0.29C6.56,0.1,7.27,0,8,0c4.41,0,8,3.59,8,8S12.41,16,8,16z"/>
</svg>
`,
  },
  {
    iconId: "iconMotorBoat",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24.09 13.8" >
	<path class="st0" d="M15.73,13.8c0,0-0.01,0-0.01,0H1.34v-1.66L0,10.81l3.35-3.35l20.74-1.67l-0.21,0.7
		c-0.91,3.03-2.36,5.05-4.55,6.34C18.24,13.47,17,13.8,15.73,13.8z M2.34,12.8h13.39c0,0,0.01,0,0.01,0c1.09,0,2.16-0.29,3.09-0.84
		c1.79-1.05,3.04-2.67,3.87-5.06L3.8,8.42l-2.38,2.38l0.92,0.92V12.8z"/>
	<path class="st0" d="M5.91,8.01L5.02,7.56l2.39-4.78l0.21-0.06c0.55-0.16,1.12-0.29,1.69-0.38L8.34,0l2.37,0.03
		c1.05,0.4,1.91,1.2,2.39,2.21c1.58,0.17,3.07,0.84,4.25,1.9h2.77l1.46,2.1l-0.82,0.57l-1.16-1.67h-2.64L16.81,5
		c-1.11-1.05-2.56-1.69-4.08-1.79l-0.32-0.02l-0.12-0.3C11.96,2.03,11.28,1.35,10.43,1h-0.6l0.9,2.17l-0.66,0.07
		c-0.66,0.07-1.33,0.2-1.97,0.38L5.91,8.01z"/>
	<polygon class="st0" points="14.33,7.1 13.4,6.74 14.39,4.14 17.15,4.14 17.15,5.14 15.08,5.14 	"/>
	<rect x="16.71" y="8.7" transform="matrix(0.9971 -0.0762 0.0762 0.9971 -0.6441 1.5138)" class="st0" width="5.63" height="1"/>
	<rect x="1.84" y="11.43" class="st0" width="1.13" height="1"/>
	<path class="st0" d="M6.64,10.83l-0.07-1L7.4,9.77l0.07,1L6.64,10.83z M10.47,10.54l-0.07-1l2.99-0.22l0.07,1L10.47,10.54z"/>
	<path class="st0" d="M4.63,6.09H2.97c-0.56,0-0.9-0.25-1.08-0.45C1.4,5.09,1.49,4.28,1.5,4.19l0.05-0.36l6.12-1.12l0.18,0.98
		L2.51,4.67c0.02,0.12,0.06,0.23,0.13,0.3c0.04,0.05,0.13,0.11,0.33,0.11h1.67V6.09z"/>
</svg>`,
  },
  {
    iconId: "iconSailBoat",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22.23 24.21" >
	<path class="st0" d="M9.78,22.22l-0.07-1l0.32-0.02l0.07,1L9.78,22.22z M11.1,22.12l-0.07-1l1-0.07l0.07,1L11.1,22.12z M13.1,21.97
		l-0.07-1l1-0.07l0.07,1L13.1,21.97z M15.09,21.82l-0.07-1l1-0.07l0.07,1L15.09,21.82z M17.09,21.67l-0.07-1l1-0.07l0.07,1
		L17.09,21.67z"/>
	<path class="st0" d="M17.96,24.21H0l1.35-4.17l20.88-2.1l0.01,0.55c0,0.05,0,4.83-4.22,5.71L17.96,24.21z M1.37,23.21h16.49
		c2.63-0.58,3.21-3.04,3.34-4.16L2.1,20.97L1.37,23.21z"/>
	<path class="st0" d="M4.96,20.44l-0.9-0.43l1.8-3.82H9.5c1.88,0.03,3.51,1.22,4.01,3.01l-0.96,0.28l-0.01-0.02
		c-0.38-1.34-1.63-2.26-3.04-2.27H6.49L4.96,20.44z"/>
	<path class="st0" d="M10.86,16.69h-1V0l0.58,0.1c3.45,0.58,6.41,2.8,7.93,5.95c0.94,1.88,1.43,4,1.39,6.11v3.12H14.5v-1h4.26v-2.13
		c0.03-1.96-0.41-3.92-1.29-5.66c-1.29-2.68-3.74-4.62-6.62-5.29V16.69z"/>
	<rect x="14.51" y="9.75" class="st0" width="4.63" height="1"/>
	<rect x="14.51" y="5.51" class="st0" width="3.28" height="1"/>
	<path class="st0" d="M8.76,16.69h-1V4.38c-2.35,2.59-4.17,5.65-5.32,8.94h3.73v1h-5.1l0.21-0.65c1.31-4.09,3.6-7.86,6.63-10.89
		l0.85-0.85V16.69z"/>
</svg>
`,
  },
  {
    iconId: "iconGulet",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24.57 19.05" >
	<path class="st0" d="M19.08,19.05C19.08,19.05,19.07,19.05,19.08,19.05H4.35c-2.32,0-3.77-2.61-4.29-3.74
		c-0.1-0.21-0.08-0.47,0.06-0.68c0.15-0.2,0.39-0.31,0.63-0.28c8.58,1.05,19.85,0.43,22.02,0.29c0.16-0.01,0.4-0.02,0.55,0.22
		L23.42,15v0.15c0,2.07-1.96,3.18-3.12,3.66C19.91,18.97,19.5,19.05,19.08,19.05z M1.24,15.42c0.54,1,1.64,2.63,3.12,2.63h14.72
		c0.26-0.01,0.57-0.05,0.84-0.17c0.82-0.33,2.12-1.04,2.43-2.2C18.69,15.89,9.11,16.31,1.24,15.42z M0.97,14.88
		c0,0,0,0.01,0.01,0.01C0.97,14.89,0.97,14.89,0.97,14.88z"/>
	<path class="st0" d="M9.08,13.47H2.24L2.2,13.01C1.65,6.07,6.31,2.99,6.51,2.86l0.98-0.63L7.27,3.38
		c-0.91,4.76,2.19,9.26,2.22,9.31l0.55,0.79H9.08z M3.17,12.47h5.01c-0.81-1.4-2.29-4.5-2.06-8C4.91,5.65,2.98,8.22,3.17,12.47z"/>
	<path class="st0" d="M24.57,13.47h-5.29l-0.05-0.44c-0.31-2.44,0.72-4.88,2.68-6.36l1.04-0.79l-0.24,1.28
		c-0.54,2.83,1.31,5.49,1.33,5.52L24.57,13.47z M20.17,12.47h2.55c-0.47-0.88-1.11-2.41-1.14-4.14
		C20.61,9.47,20.1,10.96,20.17,12.47z"/>
	<path class="st0" d="M17.24,13.47H11.2v-1h5.13c-0.97-1.64-2.95-5.62-2.57-10.09c-2.07,1.92-3.41,4.55-3.73,7.38L9.04,9.65
		c0.4-3.52,2.25-6.76,5.09-8.88L15.16,0l-0.24,1.27c-1.12,5.85,2.69,11.37,2.73,11.42l0.55,0.79H17.24z"/>
	<rect x="5.37" y="12.97" class="st0" width="1" height="2.33"/>
	<rect x="12.31" y="12.97" class="st0" width="1" height="2.33"/>
	<rect x="20.99" y="12.97" class="st0" width="1" height="2.33"/>
</svg>
`,
  },
  {
    iconId: "iconCatamaran",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 23 24.5">
	<path class="st0" d="M21.81,24.5l-4.47,0c-0.3,0-0.57-0.19-0.68-0.48l-0.86-2.24c-0.67,0.32-2.03,0.68-4.3,0.68
		c-2.27,0-3.59-0.38-4.29-0.7l-0.84,2.28C6.26,24.32,5.99,24.5,5.7,24.5H1.19C0.53,24.5,0,23.94,0,23.25V18.5h23v4.75
		C23,23.94,22.47,24.5,21.81,24.5z M17.6,23.66L17.6,23.66C17.6,23.66,17.6,23.66,17.6,23.66z M1,19.5v3.75
		c0,0.14,0.09,0.25,0.19,0.25h4.32l0.84-2.12c0.1-0.25,0.29-0.45,0.53-0.55c0.24-0.1,0.5-0.09,0.74,0.01
		c0.5,0.23,1.67,0.62,3.87,0.62c2.22,0,3.46-0.37,3.99-0.59c0.24-0.1,0.49-0.1,0.73,0.01c0.24,0.1,0.42,0.3,0.52,0.55l0.8,2.07h4.32
		c0.08-0.02,0.15-0.13,0.15-0.24V19.5H1z"/>
	<path class="st0" d="M20,19.5H3v-1.2c0-2.1,1.63-3.8,3.64-3.8h9.72c2.01,0,3.64,1.71,3.64,3.8V19.5z M4,18.5h15v-0.2
		c0-1.54-1.18-2.8-2.64-2.8H6.64C5.19,15.5,4,16.76,4,18.3V18.5z"/>
	<rect x="11" class="st0" width="1" height="15"/>
	<path class="st0" d="M18,14h-1v-0.4c0-10.87-5.45-11.1-5.51-11.1l0.02-1C11.77,1.51,18,1.76,18,13.6V14z"/>
	<rect x="13.5" y="12.5" class="st0" width="5" height="1"/>
	<rect x="3.5" y="12.5" class="st0" width="8" height="1"/>
</svg>
`,
  },
  {
    iconId: "iconFav",
    iconSvg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 14">
<path d="M14.33,1.67c-1.56-1.56-4.1-1.56-5.66,0C8.39,1.95,8.18,2.27,8,2.6C7.82,2.27,7.61,1.95,7.33,1.67
	c-1.56-1.56-4.09-1.56-5.66,0c-1.56,1.56-1.56,4.09,0,5.66L8,13.5l6.33-6.17C15.89,5.77,15.89,3.23,14.33,1.67z"/>
</svg>
`,
  },
  {
    iconId: "iconFavorite",
    iconSvg: `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 14">
<style type="text/css">
	.st1{fill:none;stroke:#E2211D;stroke-linecap:round;stroke-linejoin:round;}
</style>
<path class="st1" d="M14.33,1.67c-1.56-1.56-4.1-1.56-5.66,0C8.39,1.95,8.18,2.27,8,2.6C7.82,2.27,7.61,1.95,7.33,1.67
	c-1.56-1.56-4.09-1.56-5.66,0c-1.56,1.56-1.56,4.09,0,5.66L8,13.5l6.33-6.17C15.89,5.77,15.89,3.23,14.33,1.67z"/>
</svg>


`,
  },
]

export default icons
